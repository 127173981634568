import React from "react";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminDemoBooking from "../../components/admin/AdminDemoBooking";
import Header from "../../components/Layout/Header/Header";

const AdminBookingDemo = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <AdminSidebar />
        <div className="flex-1 flex flex-col">
          <AdminDemoBooking />
        </div>
      </div>
    </>
  );
};

export default AdminBookingDemo;
