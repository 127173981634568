import React from "react";
import EmployerSidebar from "../../components/employer/EmployerSidebar";
import Header from "../../components/Layout/Header/Header";
import EmployerCreateVacancy from "../../components/employer/EmployerCreateVacancy";

const EmployerCreateVacancyPage = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <EmployerSidebar />
        <div className="flex flex-1 flex-col bg-gray-100 ">
          <EmployerCreateVacancy />
        </div>
      </div>
    </>
  );
};

export default EmployerCreateVacancyPage;
