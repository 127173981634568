import React from "react";
import Header from "../../components/Layout/Header/Header";
import AdminSidebar from "../../components/admin/AdminSidebar";
import UserProfile from "../../components/UserProfile";

const AdminDashboard = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <AdminSidebar />
        <div className="flex flex-1 flex-col ">
          <div className="flex-1 p-6 bg-gray-100">
            <div className="bg-white p-6 ronded-lg shadow-md text-center">
              <h1 className="text-center text-3xl font-bold my-4">
                Employer Dashboard
              </h1>
              <UserProfile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
