import React from "react";
import { useUserRole } from "../../../context/userRoleContext";

import applicantBackground from "../../../assets/works/applicantBackground.png";
import recruiterBackground from "../../../assets/works/recruiterBackground.png";
import dottedLine from "../../../assets/selection/dottedLine.png";
import straightLine from "../../../assets/hero/straightLine.png";

const stepsEmployerData = [
  {
    id: 1,
    title: "STEP 1",
    description: "Login to WizardHire and create a job posting.",
  },
  {
    id: 2,
    title: "STEP 2",
    description: "Set the criteria for your ideal employee.",
  },
  {
    id: 3,
    title: "STEP 3",
    description:
      "Receive a link and a QR code, and copy-paste it anywhere you like.",
  },
];

const stepsCandidateData = [
  {
    id: 1,
    title: "STEP 1",
    description:
      "Click on the WizardHire link or scan the QR code and login to your WizardHire account.",
  },
  {
    id: 2,
    title: "STEP 2",
    description: "Upload your resume.",
  },
  {
    id: 3,
    title: "STEP 3",
    description: "Ace the interview and receive feedback",
  },
];

const Works = () => {
  const { userRole } = useUserRole();
  const steps =
    userRole === "recruiter" ? stepsEmployerData : stepsCandidateData;
  const workBackground =
    userRole === "recruiter" ? recruiterBackground : applicantBackground;
  return (
    <div
      className="flex flex-col py-10 justify-center items-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${workBackground})`,
      }}
    >
      <div
        className="container  max-w-screen-xl  mx-auto"
        data-aos="zoom-out-down"
      >
        <div className="flex md:flex-row flex-col justify-center  items-center text-white rounded-lg">
          <div className="flex flex-col justify-center items-start ">
            <img src={dottedLine} alt="" className="screen py-4" />
            <h1 className=" text-white text-5xl font-semibold">
              <span className="font-cinzel text-6xl">H</span>OW{" "}
              <span className="font-cinzel text-6xl">I</span>T <br />
            </h1>
            <h1 className="mt-2 text-white text-5xl font-semibold">
              <span className="font-cinzel text-6xl">W</span>ORKS ?
            </h1>
            <img src={straightLine} alt="" className="screen w-1/4 py-4" />
          </div>
          <div>
            {steps.map((step) => (
              <div
                className="border border-white rounded-tl-2xl rounded-br-2xl p-4 my-4 mx-4"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(30,20,13,1) 5%, rgba(11,38,70,1) 100%)",
                  opacity: 0.8,
                }}
                key={step.id}
              >
                <h3 className="text-3xl font-bold mb-2">{step.title}</h3>
                <p className="text-2xl">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
