import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import Header from "./Layout/Header/Header";
import Modal from "react-modal";
import { toast } from "react-toastify";

const SingleVacancy = () => {
  const params = useParams();
  const [vacancyInformation, setVacancyInformation] = useState(null);
  const [interviewMode, setInterviewMode] = useState("text"); // Default to text
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [secondaryModalIsOpen, setSecondaryModalIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    vacancyId: "",
    resume: "",
    workPreference: "",
    legalWork: "",
    criminalRecord: "",
  });

  const navigate = useNavigate();

  const getVacancy = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
    );
    if (data.success === true) {
      setVacancyInformation(data.singleVacancy);
    }
  };
  const handlePrimaryFormSubmit = (e) => {
    e.preventDefault();

    // Validate the form fields
    if (
      !formData.workPreference ||
      !formData.legalWork ||
      !formData.criminalRecord
    ) {
      setError("Please answer all the questions before proceeding.");
      return;
    }

    // Clear the error message if validation passes
    setError(null);
    setModalIsOpen(true);
    setSecondaryModalIsOpen(false);
  };
  const handleInterview = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Append text fields to the FormData
    formDataToSend.append("firstName", formData.firstName);
    formDataToSend.append("lastName", formData.lastName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("vacancyId", params.id);

    const resumeFile = document.getElementById("resume").files[0];
    // Append resume (file) to the FormData
    if (formData.isResumeChanged) {
      console.log("Changed File", resumeFile);
      formDataToSend.append("resume", resumeFile);
    } else {
      console.log("File is not changed");
      // Send existing resume URL if unchanged
      formDataToSend.append("resume", formData.resume);
    }
    console.log("Form Data", formDataToSend);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/applicant/createApplicant`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important header for file upload
          },
        }
      );

      if (data.success) {
        if (interviewMode === "text") {
          navigate(`/interview/text/${params.id}`, {
            state: {
              applicantId: data?.savedApplicant?._id,
              resume: data?.savedApplicant?.resume,
            },
          });
        } else if (interviewMode === "voice") {
          navigate(`/interview/voice/${params.id}`, {
            state: {
              applicantId: data?.savedApplicant?._id,
              resume: data?.savedApplicant?.resume,
            },
          });
        }
      }
    } catch (error) {
      toast.error("Some went wrong");
    }
  };
  const getcandidateData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayUser`
      );
      if (response.data.success) {
        setFormData({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          resume: response.data.user.resume,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  useEffect(() => {
    getcandidateData();
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openSecondaryModal = () => {
    setSecondaryModalIsOpen(true);
  };
  const closeSecondaryModal = () => {
    setSecondaryModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Header />

      <div className="flex items-center justify-center h-screen bg-black/75">
        {vacancyInformation ? (
          <div
            className="text-center p-6 border-white text-white bg-gradient-to-br from-[#31251d] to-[#1b2c3d] rounded-2xl w-1/2"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            <h2 className="text-2xl font-bold mb-2">
              {vacancyInformation.jobTitle}
            </h2>
            <p className=" mb-2">{vacancyInformation.companyName}</p>
            <p className=" mb-4">
              {vacancyInformation.experiencelevel.value === 0
                ? "Entry level"
                : `${vacancyInformation.experiencelevel.value} ${vacancyInformation.experiencelevel.unit}`}
            </p>
            <p className=" mb-4">{vacancyInformation.jobType}</p>
            <p className="mb-2">{vacancyInformation.typeOfWork}</p>
            <p className="mb-2">{vacancyInformation.educationalRequirements}</p>
            <p className="mb-2">{vacancyInformation?.salaryRange?.min}</p>
            <p className="mb-2">{vacancyInformation?.salaryRange?.max}</p>
            <p className=" mb-4">{vacancyInformation.skills.join(", ")}</p>
            <div className="mb-4 text-center">
              <h3 className="text-lg font-semibold">Job Description</h3>
              <div
                className="job-description"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(vacancyInformation.jobDescription),
                }}
              ></div>
            </div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
              onClick={openSecondaryModal}
            >
              Apply Now
            </button>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>
      <Modal
        isOpen={secondaryModalIsOpen}
        onRequestClose={closeSecondaryModal}
        className="w-1/2 h-auto mx-auto mt-40 p-8 rounded-lg shadow-lg border border-white text-white bg-gradient-to-br from-[#31251d] to-[#1b2c3d]"
      >
        <h2 className="text-xl mb-4">Additional Questions</h2>
        <form onSubmit={handlePrimaryFormSubmit}>
          <div>
            <label htmlFor="work-preference">
              This job requires applicant to be able to work
              {vacancyInformation && " " + vacancyInformation?.typeOfWork}. Are
              you okay with it?
            </label>
            <div className="flex items-center space-x-4">
              <label>
                <input
                  type="radio"
                  id="work-preference-yes"
                  name="workPreference"
                  value="yes"
                  checked={formData.workPreference === "yes"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  id="work-preference-no"
                  name="workPreference"
                  value="no"
                  checked={formData.workPreference === "no"}
                  onChange={handleChange}
                  className="mr-2"
                />
                No
              </label>
            </div>
          </div>

          <div>
            <label className="block mb-1" htmlFor="legalWork">
              Are you able to legally work in Canada?
            </label>
            <div className="flex items-center space-x-4">
              <label>
                <input
                  type="radio"
                  id="legalWork"
                  name="legalWork"
                  value="yes"
                  checked={formData.legalWork === "yes"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  id="legalWork"
                  name="legalWork"
                  value="no"
                  checked={formData.legalWork === "no"}
                  onChange={handleChange}
                  className="mr-2"
                />
                No
              </label>
            </div>
          </div>

          <div>
            <label className="block mb-1" htmlFor="criminalRecord">
              Have you ever been convicted of a criminal offense that has not
              been sealed, expunged, or pardoned?
            </label>
            <div className="flex items-center space-x-4">
              <label>
                <input
                  type="radio"
                  id="criminalRecord"
                  name="criminalRecord"
                  value="yes"
                  checked={formData.criminalRecord === "yes"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  id="criminalRecord"
                  name="criminalRecord"
                  value="no"
                  checked={formData.criminalRecord === "no"}
                  onChange={handleChange}
                  className="mr-2"
                />
                No
              </label>
            </div>
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeSecondaryModal}
              className="mr-2 px-4 py-2 border rounded  bg-[#7b828a] "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Next
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create Applicant"
        className="w-1/2 h-auto mx-auto mt-32 p-8 rounded-lg shadow-lg border border-white text-white bg-gradient-to-br from-[#31251d] to-[#1b2c3d]"
      >
        <h2 className="text-xl mb-4">Apply</h2>
        <form onSubmit={handleInterview}>
          <div>
            <label className="block mb-1" htmlFor="firstName">
              First Name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded  bg-[#7b828a]"
              required
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="lastName">
              Last Name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded  bg-[#7b828a]"
              required
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded  bg-[#7b828a]"
              required
            />
          </div>

          <div>
            <label className="block mb-1" htmlFor="resume">
              Resume:
            </label>
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setFormData({
                    ...formData,
                    resume: file,
                    isResumeChanged: true,
                  });
                }
              }}
              className="w-full px-3 py-2 border rounded bg-[#7b828a] "
              required={!formData?.resume}
            />
            {formData?.resume &&
              (console.log("Resume Data URL:", formData.resume),
              (
                <div className="mt-2 flex items-center space-x-4">
                  <a
                    href={
                      formData.isResumeChanged
                        ? URL.createObjectURL(formData.resume) // Temporary URL for new file
                        : formData.resume // Existing URL for current file
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    View Current Resume
                  </a>
                  <button
                    type="button"
                    onClick={() => {
                      setFormData({ ...formData, resume: null });
                      document.getElementById("resume").value = ""; // Reset file input
                    }}
                    className="px-3 py-1 bg-red-500 text-white rounded"
                  >
                    Remove File
                  </button>
                </div>
              ))}
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold">
              Choose Interview Mode:
            </label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="text"
                name="interviewMode"
                value="text"
                checked={interviewMode === "text"}
                onChange={() => setInterviewMode("text")}
                className="mr-2"
              />
              <label htmlFor="text" className="mr-4">
                Text Interview
              </label>

              <input
                type="radio"
                id="voice"
                name="interviewMode"
                value="voice"
                checked={interviewMode === "voice"}
                onChange={() => setInterviewMode("voice")}
                className="mr-2"
              />
              <label htmlFor="voice">Voice Interview</label>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="mr-2 px-4 py-2 border rounded  bg-[#7b828a] "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Take Interview
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SingleVacancy;
