import React from "react";
import AdminSidebar from "../../components/admin/AdminSidebar";
import ChangePassword from "../../features/ChangePassword";
import DeleteAccount from "../../features/DeleteAccount";
import Header from "../../components/Layout/Header/Header";

const AdminSettingPage = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <AdminSidebar />
        <div className="flex flex-1 flex-col ">
          <Header />

          <div className="flex p-6 bg-gray-100">
            <div className="flex-1 bg-white p-6 ronded-lg shadow-md text-center">
              <ChangePassword />
            </div>
          </div>
          <div className="bg-white p-6 ronded-lg shadow-md text-center">
            <DeleteAccount />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettingPage;
