import React from "react";
import EmployerSidebar from "../../components/employer/EmployerSidebar";
import Header from "../../components/Layout/Header/Header";
import EmployerEditVacancy from "../../components/employer/EmployerEditVacancy";

const EmployerEditSingleVacancyPage = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <EmployerSidebar />
        <div className="flex flex-1 flex-col ">
          <div className="flex-1 p-6 bg-gray-100">
            <div className="bg-white p-6 ronded-lg shadow-md text-center">
              <EmployerEditVacancy />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployerEditSingleVacancyPage;
