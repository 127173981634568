import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

const UserProfile = () => {
  const [userData, setUserData] = useState();
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayUser`
      );
      if (response.data.success) {
        setUserData(response.data.user);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {userData ? (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">First Name:</strong>
            <span className="ml-2 text-gray-900">{userData.firstName}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Last Name:</strong>
            <span className="ml-2 text-gray-900">{userData.lastName}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Email:</strong>
            <span className="ml-2 text-gray-900">{userData.email}</span>
          </div>
        </div>
      ) : (
        <div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
