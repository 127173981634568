import React from "react";
import Layout from "../Layout/Layout";

const ComingSoon = () => {
  return (
    <Layout title="WizardHire AI - Coming Soon">
      <div className="flex flex-col items-center justify-center min-h-[60vh] bg-black bg-opacity-80 text-white text-center px-4">
        <h1 className="text-4xl md:text-6xl font-bold mb-6">🚀 Coming Soon!</h1>
        <p className="text-lg md:text-2xl mb-8">
          WizardHire AI is almost here. Stay tuned for something amazing!
        </p>
      </div>
    </Layout>
  );
};

export default ComingSoon;
