// src/components/Login.js
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../../components/Layout/Header/Header";
import { useGoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || "/";

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/login`,
        {
          email,
          password,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setAuth({
          ...auth,
          user: response.data.user,
          token: response.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(response.data));
        navigate(from, { replace: true });
      } else {
        console.log(response);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Login Failed");
    }
  };

  const responseGoogle = async (authResult) => {
    try {
      console.log("Auth");
      if (authResult["code"]) {
        const { code } = authResult;
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/auth/google?code=${code}`
        );
        if (data?.success) {
          toast.success("Google login successful");
          if (data?.needsPassword) {
            navigate("/set-password", { state: { user: data.user } });
          } else {
            setAuth({
              ...auth,
              user: data.user,
              token: data.token,
            });
            localStorage.setItem("auth", JSON.stringify(data));
            navigate(from, { replace: true });
          }
        } else {
          toast.error("Gmail Already Used Please Login");
          navigate("/login");
        }
      }
    } catch (error) {
      console.log("Error while requestion the google code: ", error);
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: "auth-code",
  });

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center  bg-black/75">
        <div className="max-w-md w-full border-white text-white bg-gradient-to-br from-[#31251d] to-[#1b2c3d] rounded-2xl shadow-md rounded p-8 space-y-6">
          <h2 className="text-center text-2xl font-bold">Login</h2>
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label className="block ">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 bg-[#7b828a] border rounded focus:outline-none focus:ring focus:border-blue-300"
                required
              />
            </div>
            <div>
              <label className="block ">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 bg-[#7b828a] border rounded focus:outline-none focus:ring focus:border-blue-300"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              Login
            </button>
          </form>
          <button
            onClick={googleLogin}
            className="w-full text-center bg-blue-500 py-2  hover:bg-blue-60"
          >
            Login with google
          </button>
          <button
            onClick={() => navigate("/signup")}
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          >
            Sign Up
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
