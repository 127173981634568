import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import logoHat from "../../assets/selection/logoHat.png";

const DemoBookingModal = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    aboutCompany: "",
    atsUsed: "",
    estimatedRecruitmentsPerMonth: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/demoBooking/createDemoBooking`,
        formData
      );
      if (response.data.success) {
        toast.success("Your application has been submitted", {
          className: "custom-toast",
        });
        setFormData({
          companyName: "",
          aboutCompany: "",
          atsUsed: "",
          estimatedRecruitmentsPerMonth: "",
        });
        onRequestClose();
      }
    } catch (error) {
      console.error("Error booking demo:", error);
      alert("Failed to book demo. Please try again.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="w-1/2 h-auto mx-auto mt-20 p-8 rounded-lg shadow-lg border border-white text-white bg-gradient-to-br from-[#31251d] to-[#1b2c3d]"
      overlayClassName="fixed inset-0 flex justify-center items-center"
    >
      <div className="text-center flex justify-center items-center flex-col">
        <img src={logoHat} alt="Logo" className="w-24" />
        <h2 className="text-2xl font-bold mb-4 text-center">Book Your Demo</h2>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <label>
          Company Name:
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            className="border border-gray-300 rounded p-2 w-full bg-[#7b828a] "
            required
          />
        </label>
        <label>
          About Company:
          <textarea
            name="aboutCompany"
            value={formData.aboutCompany}
            onChange={handleInputChange}
            className="border border-gray-300 rounded p-2 w-full bg-[#7b828a] "
            required
          />
        </label>
        <label>
          Applicant Tracking System (If any):
          <input
            type="text"
            name="atsUsed"
            value={formData.atsUsed}
            onChange={handleInputChange}
            className="border border-gray-300 rounded p-2 w-full bg-[#7b828a] "
          />
        </label>
        <label>
          Estimate number of hires per month:
          <input
            type="number"
            name="estimatedRecruitmentsPerMonth"
            value={formData.estimatedRecruitmentsPerMonth}
            onChange={handleInputChange}
            className="border border-gray-300 rounded p-2 w-full bg-[#7b828a] "
            required
          />
        </label>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={onRequestClose}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DemoBookingModal;
