import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../styles/modalStyles";
const AdminDemoBooking = () => {
  const [demoBooking, setDemoBooking] = useState([]);

  const displayEmployers = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/demoBooking/displayDemoBooking`
      );
      console.log(data);
      if (data.success) {
        setDemoBooking(data.demoBookingInfo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    displayEmployers();
  }, []);

  return (
    <>
      <div className="bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
          <h2 className="text-2xl font-bold mb-6 text-center">Demo Booking</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    Company Name
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    About Company
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    ATS Used
                  </th>
                  <th className="py-2 px-4 border-b-2 border-gray-300">
                    Estimated Recruitments(Per Month)
                  </th>
                </tr>
              </thead>
              <tbody>
                {demoBooking.map((demoBook, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {demoBook.companyName}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {demoBook.aboutCompany}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {demoBook.atsUsed}
                    </td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      {demoBook.estimatedRecruitmentsPerMonth}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDemoBooking;
