import React from "react";
import trustBackground from "../../assets/about/trustBackground.png";

const AboutTrustSection = () => {
  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${trustBackground})`,
      }}
    >
      <div
        className="container max-w-screen-lg mx-auto text-center text-white"
        data-aos="fade-down-right"
      >
        {/* Headline Section */}
        <div className="bg-white text-black py-4 px-6 rounded-full inline-block mb-8 shadow-lg">
          <h2 className="text-3xl font-bold uppercase">
            But WizardHire is not just about tech—it's about trust.
          </h2>
        </div>

        {/* Text Section */}
        <p className="text-2xl leading-relaxed mx-auto mb-6">
          We know the scamming landscape has made people wary, which is why
          we’ve placed security at the heart of our development process. With
          advanced encryption and verification measures, we’ve created a secure,
          scam-free environment that protects both candidates and employers.
          Your data is safe with us.
        </p>

        <p className="text-2xl leading-relaxed mx-auto">
          Our global, diverse team of developers, HR experts, and innovators are
          constantly working to refine and improve the platform. We believe in
          building a system that adapts to the unique needs of each employer
          while ensuring a level playing field for all candidates. WizardHire
          stands out from larger platforms by offering a proactive approach to
          candidate selection, removing the need for companies to sift through
          countless profiles. Instead, our AI filters and matches the right
          talent based on customized criteria, saving time and resources in the
          recruitment process.
        </p>
      </div>
    </div>
  );
};

export default AboutTrustSection;
