import React from "react";
import AdminSidebar from "../../components/admin/AdminSidebar";
import Header from "../../components/Layout/Header/Header";
import AdminProfile from "../../components/admin/AdminProfile";

const AdminProfilePage = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <AdminSidebar />
        <div className="flex flex-1 flex-col ">
          <div className="flex-1 p-6 bg-gray-100">
            <div className="bg-white p-6 ronded-lg shadow-md text-center">
              <AdminProfile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProfilePage;
