import React, { useState, useEffect, useRef } from "react";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EmployerCreateVacancy = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    jobTitle: "",
    experienceValue: "",
    experienceUnit: "months",
    jobType: "full-time",
    skills: [], // Initialize as an array
    jobDescription: "",
    startDate: "",
    endDate: "",
    educationalRequirements: "Bachelor's Degree",
    typeOfWork: "In person",
    salaryRangemin: "",
    salaryRangemax: "",
  });
  const navigate = useNavigate();

  const quillRef = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      editorRef.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }],
            ["link"],
            ["clean"],
          ],
        },
      });

      editorRef.current.on("text-change", () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          jobDescription: editorRef.current.root.innerHTML,
        }));
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleExperienceUnitChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experienceUnit: e.target.value,
    }));
  };

  const handleSkillsChange = (e) => {
    const skillsString = e.target.value;
    // Ensure skills are always stored as an array
    const skillsArray = skillsString.split(",").map((skill) => skill.trim());
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: skillsArray,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const {
        companyName,
        jobTitle,
        experienceValue,
        experienceUnit,
        jobType,
        skills,
        jobDescription,
        startDate,
        endDate,
        educationalRequirements,
        typeOfWork,
        salaryRangemin,
        salaryRangemax,
      } = formData;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/createVacancy`,
        {
          companyName,
          jobTitle,
          experiencelevel: {
            value: Number(experienceValue),
            unit: experienceUnit,
          },
          jobType,
          skills,
          jobDescription,
          startDate,
          endDate,
          educationalRequirements,
          typeOfWork,
          salaryRange: {
            min: Number(salaryRangemin),
            max: Number(salaryRangemax),
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setFormData({
          companyName: "",
          jobTitle: "",
          experienceValue: "",
          experienceUnit: "months",
          jobType: "full-time",
          skills: [], // Reset to an empty array
          jobDescription: "",
          startDate: "",
          endDate: "",
          educationalRequirements: "Bachelor's Degree",
          typeOfWork: "In person",
          salaryRangemin: "",
          salaryRangemax: "",
        });
        editorRef.current.root.innerHTML = ""; // Clear Quill editor
        navigate("/dashboard/employer/vacancyInfromation");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.error("Error creating vacancy:", error);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto space-y-8">
      <form onSubmit={handleSubmit}>
        <h2 className="text-3xl font-semibold text-gray-900 text-center mb-6">
          Create Vacancy
        </h2>

        {/* Company Name & Job Title */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        {/* Experience */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Experience Value
            </label>
            <input
              type="number"
              name="experienceValue"
              min="0"
              value={formData.experienceValue}
              onChange={handleChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Experience Unit
            </label>
            <select
              name="experienceUnit"
              value={formData.experienceUnit}
              onChange={handleExperienceUnitChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="months">Months</option>
              <option value="years">Years</option>
            </select>
          </div>
        </div>

        {/* Job Type and Skills */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Job Type
            </label>
            <select
              name="jobType"
              value={formData.jobType}
              onChange={handleChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="intern">Intern</option>
            </select>
          </div>

          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Skills (comma-separated) For Eg: communication,leadership
            </label>
            <input
              type="text"
              name="skills"
              value={formData.skills.join(",")}
              onChange={handleSkillsChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        {/* Educational Requirements */}
        <div className="mb-6">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Educational Requirements
          </label>
          <select
            name="educationalRequirements"
            value={formData.educationalRequirements}
            onChange={handleChange}
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="No Formal Education">No Formal Education</option>
            <option value="High School Diploma or Equivalent">
              High School Diploma or Equivalent(e.g., GED)
            </option>
            <option value="Some College (No Degree)">
              Some College (No Degree)
            </option>
            <option value="Associate Degree">
              Associate Degree (e.g., AA, AS)
            </option>
            <option value="Bachelor's Degree">
              Bachelor's Degree (e.g., BA, BS)
            </option>
            <option value="Master's Degree">
              Master's Degree(e.g., MA, MS, MBA)
            </option>
            <option value="Doctorate or Higher">
              Doctorate or Higher (e.g., PhD, MD, JD)
            </option>
            <option value="Professional Certification or Vocational Training">
              Professional Certification or Vocational Training
            </option>
          </select>
        </div>

        {/* Type of Work */}
        <div className="mb-6">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Type of Work
          </label>
          <select
            name="typeOfWork"
            value={formData.typeOfWork}
            onChange={handleChange}
            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="In person">In person</option>
            <option value="Remote">Remote</option>
            <option value="Hybrid">Hybrid</option>
          </select>
        </div>

        {/* Salary Range */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Min Salary (CAD)
            </label>
            <input
              type="number"
              name="salaryRangemin"
              min="0"
              value={formData.salaryRangemin}
              onChange={handleChange}
              placeholder="Min Salary"
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Max Salary (CAD)
            </label>
            <input
              type="number"
              name="salaryRangemax"
              min="0"
              value={formData.salaryRangemax}
              onChange={handleChange}
              placeholder="Max Salary"
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Job Description (Quill Editor) */}
        <div className="mb-6">
          <label className="block text-lg font-semibold text-gray-700 mb-2">
            Job Description
          </label>
          <div
            ref={quillRef}
            style={{ height: "300px" }}
            className="border border-gray-300 rounded-lg p-4"
          ></div>
        </div>

        {/* Start and End Dates */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Start Date
            </label>
            <input
              type="date"
              name="startDate"
              onChange={handleChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              End Date
            </label>
            <input
              type="date"
              name="endDate"
              onChange={handleChange}
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between gap-4">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-300"
          >
            Create Vacancy
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployerCreateVacancy;
