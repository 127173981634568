import React, { useState, useEffect, useRef } from "react";
import { Mic, MicOff, Send } from "lucide-react";

const InterviewClient = () => {
  const [mode, setMode] = useState("text");
  const [connected, setConnected] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const ws = useRef(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    initializeInterview();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const initializeInterview = async () => {
    try {
      const response = await fetch("http://localhost:5000/api/interviews", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mode }),
      });
      const data = await response.json();
      setSessionId(data.sessionId);

      ws.current = new WebSocket("ws://localhost:5000");

      ws.current.onopen = () => {
        setConnected(true);
        setError("");
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.error) {
          setError(data.error);
          return;
        }
        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: data.type === "audio" ? data.response : data.content,
            timestamp: data.timestamp,
            type: data.type,
            transcription: data.transcription,
          },
        ]);
        setIsProcessing(false);
      };

      ws.current.onerror = () => {
        setError("WebSocket connection error");
        setConnected(false);
      };

      ws.current.onclose = () => {
        setConnected(false);
      };
    } catch {
      setError("Error initializing interview");
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          const base64Audio = reader.result.split(",")[1];
          sendMessage(base64Audio, "audio");
        };
      };

      mediaRecorder.current.start();
      setIsRecording(true);
      setError("");
    } catch {
      setError("Error accessing microphone");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setIsProcessing(true);
    }
  };

  const sendMessage = (content, type = "text") => {
    if (!ws.current || !connected) {
      setError("No connection to server");
      return;
    }

    setIsProcessing(true);
    ws.current.send(
      JSON.stringify({
        sessionId,
        type,
        content,
        mode,
      })
    );

    if (type === "text") {
      setMessages((prev) => [
        ...prev,
        { role: "user", content, timestamp: new Date(), type },
      ]);
      setCurrentMessage("");
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 border rounded-lg shadow-md bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          AI-Powered Interview ({mode} Mode)
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setMode("text")}
            className={`px-4 py-2 border rounded ${
              mode === "text" ? "bg-blue-500 text-white" : "bg-gray-100"
            }`}
          >
            Text
          </button>
          <button
            onClick={() => setMode("voice")}
            className={`px-4 py-2 border rounded ${
              mode === "voice" ? "bg-blue-500 text-white" : "bg-gray-100"
            }`}
          >
            Voice
          </button>
        </div>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-600 rounded">{error}</div>
      )}

      <div className="border rounded-lg p-4 h-96 overflow-y-auto bg-gray-50">
        {messages.map((msg, idx) => (
          <div
            key={idx}
            className={`mb-4 p-3 rounded-lg ${
              msg.role === "user" ? "bg-blue-100 ml-8" : "bg-white mr-8"
            }`}
          >
            <div className="font-semibold mb-1">
              {msg.role === "user" ? "You" : "AI Interviewer"}:
            </div>
            {msg.type === "audio" && msg.transcription && (
              <div className="text-sm text-gray-500 mb-2">
                Transcription: {msg.transcription}
              </div>
            )}
            <div>{msg.content}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {mode === "text" ? (
        <div className="flex space-x-2 mt-4">
          <input
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            placeholder="Type your response..."
            disabled={isProcessing}
            className="flex-1 px-4 py-2 border rounded"
            onKeyPress={(e) =>
              e.key === "Enter" && !isProcessing && sendMessage(currentMessage)
            }
          />
          <button
            onClick={() => sendMessage(currentMessage)}
            disabled={!currentMessage || isProcessing}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            <Send className="w-4 h-4" />
          </button>
        </div>
      ) : (
        <button
          onClick={isRecording ? stopRecording : startRecording}
          className={`mt-4 w-full px-4 py-2 rounded ${
            isRecording ? "bg-red-500 text-white" : "bg-blue-500 text-white"
          }`}
          disabled={isProcessing}
        >
          {isRecording ? (
            <>
              <MicOff className="w-4 h-4 mr-2" />
              Stop Recording
            </>
          ) : (
            <>
              <Mic className="w-4 h-4 mr-2" />
              {isProcessing ? "Processing..." : "Start Recording"}
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default InterviewClient;
