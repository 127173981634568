import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const EmployerEditVacancy = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [vacancyInformation, setVacancyInformation] = useState(null);
  const [formData, setFormData] = useState({
    companyName: "",
    experiencelevel: {
      value: "",
      unit: "months",
    },
    skills: [],
    jobTitle: "",
    jobType: "full-time",
    jobDescription: "",
    startDate: "",
    endDate: "",
    educationalRequirements: "Bachelor's Degree",
    typeOfWork: "In person",
    salaryRangemin: "",
    salaryRangemax: "",
  });

  // Fetch vacancy data
  const getVacancy = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
      );
      console.log("Data", data);
      if (data.success) {
        setVacancyInformation(data.singleVacancy);
        setFormData({
          companyName: data.singleVacancy.companyName,
          experiencelevel: {
            value: data.singleVacancy.experiencelevel?.value || "",
            unit: data.singleVacancy.experiencelevel?.unit || "months",
          },
          skills: Array.isArray(data.singleVacancy.skills)
            ? data.singleVacancy.skills
            : [],
          jobTitle: data.singleVacancy.jobTitle,
          jobType: data.singleVacancy.jobType,
          jobDescription: data.singleVacancy.jobDescription,
          startDate: new Date(data.singleVacancy.startDate)
            .toISOString()
            .split("T")[0],
          endDate: new Date(data.singleVacancy.endDate)
            .toISOString()
            .split("T")[0],
          educationalRequirements: data.singleVacancy.educationalRequirements,
          typeOfWork: data.singleVacancy.typeOfWork,
          salaryRange: {
            min: data.singleVacancy.salaryRange?.min,
            max: data.singleVacancy.salaryRange?.max,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching vacancy:", error);
    }
  };

  useEffect(() => {
    getVacancy();
  }, [params.id]);

  const quillRef = useRef(null);
  const editorRef = useRef(null);
  // Initialize Quill editor only once
  useEffect(() => {
    if (quillRef.current) {
      editorRef.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }],
            ["link"],
            ["clean"],
          ],
        },
      });
      editorRef.current.root.innerHTML = formData.jobDescription;
    }
  }, []);

  // Update editor content when formData.jobDescription changes
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.root.innerHTML = formData.jobDescription;
    }
  }, [formData.jobDescription]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSkillsChange = (e) => {
    const skillsString = e.target.value;
    const skillsArray = skillsString.split(",").map((skill) => skill.trim());
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: skillsArray,
    }));
  };

  const handleExperienceValueChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiencelevel: {
        ...prevFormData.experiencelevel,
        value: e.target.value,
      },
    }));
  };
  const handleExperienceUnitChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiencelevel: {
        ...prevFormData.experiencelevel,
        unit: e.target.value,
      },
    }));
  };
  const handleSalaryRangeMinChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      salaryRange: {
        ...prevFormData.salaryRange,
        min: e.target.value,
      },
    }));
  };

  const handleSalaryRangeMaxChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      salaryRange: {
        ...prevFormData.salaryRange,
        max: e.target.value,
      },
    }));
  };

  const handleSave = async () => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/updateVacancy`,
        {
          ...formData,
          experienceValue: formData.experiencelevel.value,
          experienceUnit: formData.experiencelevel.unit,
          jobDescription: editorRef.current.root.innerHTML,
          id: vacancyInformation._id,
          educationalRequirements: formData.educationalRequirements,
          typeOfWork: formData.typeOfWork,
          salaryRangemin: formData?.salaryRange?.min,
          salaryRangemax: formData?.salaryRange?.max,
        }
      );

      if (data.success) {
        toast.success(data.message);
        setVacancyInformation((prev) => ({ ...prev, ...formData }));
        navigate(
          `/dashboard/employer/vacancyInfromation/${vacancyInformation._id}`
        );
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error updating vacancy:", error);
    }
  };
  const handleCancel = () => {
    navigate(
      `/dashboard/employer/vacancyInfromation/${vacancyInformation._id}`
    );
  };

  return (
    <div className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden">
      {console.log("formData", formData)}
      <form>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Company Name
          </label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex">
          <div className="flex w-full gap-2">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Experience Value
              </label>
              <input
                type="number"
                name="value"
                min="0"
                value={formData.experiencelevel.value}
                onChange={handleExperienceValueChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Experience Unit
              </label>
              <select
                name="unit"
                value={formData.experiencelevel.unit}
                onChange={handleExperienceUnitChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                <option value="months">Months</option>
                <option value="years">Years</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Min Salary (CAD)
            </label>
            <input
              type="number"
              name="salaryRangemin"
              min="0"
              value={formData?.salaryRange?.min}
              onChange={handleSalaryRangeMinChange}
              placeholder="Min Salary"
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-lg font-semibold text-gray-700 mb-2">
              Max Salary (CAD)
            </label>
            <input
              type="number"
              name="salaryRangemax"
              min="0"
              value={formData?.salaryRange?.max}
              onChange={handleSalaryRangeMaxChange}
              placeholder="Max Salary"
              className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Job Title
          </label>
          <input
            type="text"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Type of Work
          </label>
          <select
            name="typeOfWork"
            value={formData.typeOfWork}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="In person">In person</option>
            <option value="Remote">Remote</option>
            <option value="Hybrid">Hybrid</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Educational Requirements
          </label>
          <select
            name="educationalRequirements"
            value={formData.educationalRequirements}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="No Formal Education">No Formal Education</option>
            <option value="High School Diploma or Equivalent">
              High School Diploma or Equivalent(e.g., GED)
            </option>
            <option value="Some College (No Degree)">
              Some College (No Degree)
            </option>
            <option value="Associate Degree">
              Associate Degree (e.g., AA, AS)
            </option>
            <option value="Bachelor's Degree">
              Bachelor's Degree (e.g., BA, BS)
            </option>
            <option value="Master's Degree">
              Master's Degree(e.g., MA, MS, MBA)
            </option>
            <option value="Doctorate or Higher">
              Doctorate or Higher (e.g., PhD, MD, JD)
            </option>
            <option value="Professional Certification or Vocational Training">
              Professional Certification or Vocational Training
            </option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Skills (comma-separated)
          </label>
          <input
            type="text"
            name="skills"
            value={formData.skills.join(",")}
            onChange={handleSkillsChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Job Type
          </label>
          <select
            name="jobType"
            value={formData.jobType}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="full-time">Full-time</option>
            <option value="part-time">Part-time</option>
            <option value="contract">Contract</option>
            <option value="internship">Internship</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Start Date</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate} //  formData.startDate
            onChange={handleChange}
            className="mt-1 w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">End Date</label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate} //  formData.endDate
            onChange={handleChange}
            className="mt-1 w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Job Description
          </label>
          <div
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ref={quillRef}
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployerEditVacancy;
