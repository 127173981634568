import React from "react";
import CandidateSidebar from "../../components/candidate/CandidateSidebar";
import Header from "../../components/Layout/Header/Header";
import Applicant from "../../components/candidate/Applicant";

const CandidateApplicantPage = () => {
  return (
    <>
      <Header className="bg-black" />
      <div className="flex min-h-screen mt-16">
        <CandidateSidebar />
        <div className="flex flex-1 flex-col ">
          <div className="flex-1 p-6 bg-gray-100">
            <div className="bg-white p-6 ronded-lg shadow-md text-center">
              <Applicant />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateApplicantPage;
