import React from "react";
import landAcknowledgementSection from "../../assets/about/landAcknowledgementSection.png";

const LandAcknowledgementSection = () => {
  return (
    <div
      className="flex bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${landAcknowledgementSection})`,
      }}
    >
      <div
        className="container mx-auto  max-w-screen-lg  text-white flex items-center "
        data-aos="fade-left"
      >
        <div className="md:w-1/2 text-center justify-center text-xl md:text-xl">
          <p>
            WizardHire occupies space in Winnipeg, Manitoba, which is located on
            Treaty 1 territory. This land is the homelands and traditional
            territory of the Anishinaabeg, Ininew/Nehiyaw, Anisininew, Dakota
            Oyate, and Dene peoples, and homelands of the Red River Metis.
          </p>
          <p className="mt-5">
            We give special thanks to Shoal Lake 40 First Nation in Treaty 3,
            which is where the water that Winnipeg benefits from is sourced. We
            acknowledge the harms done to Indigenous peoples as a result of
            colonization, and we dedicate ourselves to moving forward in
            partnership with Indigenous communities in a spirit of
            reconciliation and collaboration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandAcknowledgementSection;
