import React, { useState } from "react";
import waitingForBackground from "../../assets/services/waitingForBackground.png";
import applicantWaitingForBackground from "../../assets/services/applicantWaitingForBackground.png";
import { useUserRole } from "../../context/userRoleContext";
import DemoBookingModal from "../demoBookingModal/DemoBookingModal";
import { useNavigate } from "react-router-dom";

const ServiceWaitingFor = () => {
  const { userRole } = useUserRole();
  const waitingBackground =
    userRole === "recruiter"
      ? waitingForBackground
      : applicantWaitingForBackground;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${waitingBackground})`,
      }}
    >
      <div
        className="bg-black bg-opacity-70 p-10 rounded-lg text-white text-center max-w-screen-lg"
        data-aos="fade-zoom-in"
      >
        <div className="mb-8">
          <h1 className="text-white text-5xl">
            <span className="font-cinzel text-6xl">S</span>O{" "}
            <span className="font-cinzel text-6xl">W</span>HAT{" "}
            <span className="font-cinzel text-6xl">A</span>RE
            <br />
            <span className="font-cinzel text-6xl">Y</span>OU{" "}
            <span className="font-cinzel text-6xl">W</span>AITING{" "}
            <span className="font-cinzel text-6xl">F</span>OR ?
          </h1>
        </div>
        {/* Button */}
        {userRole === "recruiter" ? (
          <button
            className="px-12 py-2 border border-white rounded-2xl hover:bg-white hover:text-black text-3xl "
            onClick={openModal}
          >
            <span className="text-4xl font-cinzel">B</span>OOK{" "}
            <span className="text-4xl font-cinzel">Y</span>OUR{" "}
            <span className="text-4xl font-cinzel">D</span>EMO{" "}
            <span className="text-4xl font-cinzel">N</span>OW
          </button>
        ) : (
          <button
            className="px-12 py-2 border border-white rounded-2xl hover:bg-white hover:text-black text-3xl"
            onClick={() => navigate("/comingSoon")}
          >
            <span className="text-4xl font-cinzel">S</span>TART{" "}
            <span className="text-4xl font-cinzel">N</span>OW{" "}
          </button>
        )}
      </div>
      <DemoBookingModal isOpen={isModalOpen} onRequestClose={closeModal} />
    </div>
  );
};

export default ServiceWaitingFor;
